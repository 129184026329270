import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_chargeboxes`;

const create = (data) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    Item: data,
    ConditionExpression: 'attribute_not_exists(#id)',
    ExpressionAttributeNames: {
      '#id': 'id',
    },
    ReturnConsumedCapacity: 'NONE',
    ReturnItemCollectionMetrics: 'NONE',
    ReturnValues: 'NONE',
  };

  return DDB.put(params).promise();
};

const edit = (id, data) => {
  if (Object.keys(data).length === 0) {
    return;
  }
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    Key: { id },
    UpdateExpression: '',
    ConditionExpression: 'attribute_exists(#id)',
    ExpressionAttributeNames: { '#id': 'id' },
    ReturnConsumedCapacity: 'NONE',
    ReturnItemCollectionMetrics: 'NONE',
    ReturnValues: 'NONE',
  };
  let sets = [];
  let removes = [];

  if (data.hasOwnProperty('sets')) {
    params.ExpressionAttributeValues = {};
    Object.keys(data.sets).forEach((field) => {
      sets.push(`#${field} = :${field}`);
      params.ExpressionAttributeNames[`#${field}`] = field;
      params.ExpressionAttributeValues[`:${field}`] = data.sets[field];
    });
  }
  if (data.hasOwnProperty('removes')) {
    data.removes.forEach((field) => {
      removes.push(`#${field}`);
      params.ExpressionAttributeNames[`#${field}`] = field;
    });
  }

  if (sets.length > 0) {
    params.UpdateExpression += 'set ' + sets.join(',');
  }
  if (removes.length > 0) {
    params.UpdateExpression += ' remove ' + removes.join(',');
  }

  if (params.UpdateExpression.length === 0) {
    return;
  }
  console.log(params);
  return DDB.update(params).promise();
};

const remove = (id) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    Key: {
      id: id,
    },
    ReturnConsumedCapacity: 'NONE',
    ReturnItemCollectionMetrics: 'NONE',
    ReturnValues: 'NONE',
  };

  return DDB.delete(params).promise();
};

const query = async (requestor, getCount, paginationOptions, filters) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    ReturnConsumedCapacity: 'NONE',
    Select: getCount ? 'COUNT' : 'ALL_ATTRIBUTES',
  };

  let clientIndex = {
    enable: false,
    id: '',
  };
  if (requestor.type !== 'superuser') {
    clientIndex.enable = true;
    clientIndex.id = requestor.client;
  }

  let filterExpression = [];
  if (filters && Object.keys(filters).length > 0) {
    params.ExpressionAttributeNames = {};
    params.ExpressionAttributeValues = {};
    if (typeof filters.client === 'string' && filters.client.length > 0) {
      clientIndex.enable = true;
      clientIndex.id = filters.client;
    }
    if (typeof filters.alias === 'string' && filters.alias.length > 0) {
      filterExpression.push('contains(#lc_alias, :lc_alias)');
      params.ExpressionAttributeNames['#lc_alias'] = 'lc_alias';
      params.ExpressionAttributeValues[':lc_alias'] = filters.alias.toLowerCase();
    }
    if (typeof filters.connected === 'boolean') {
      filterExpression.push('#connected = :connected');
      params.ExpressionAttributeNames['#connected'] = 'connected';
      params.ExpressionAttributeValues[':connected'] = filters.connected;
    } else if (filters.connected === null) {
      filterExpression.push('attribute_not_exists(#connected)');
      params.ExpressionAttributeNames['#connected'] = 'connected';
    }

    if (typeof filters.active === 'boolean') {
      filterExpression.push('#active = :active');
      params.ExpressionAttributeNames['#active'] = 'active';
      params.ExpressionAttributeValues[':active'] = filters.active;
    }
    if (filters.type && Object.keys(filters.type).length > 0) {
      params.ExpressionAttributeNames['#type'] = 'type';
      if (typeof filters.type.msg === 'string' && filters.type.msg.length > 0) {
        filterExpression.push('#type.#msg = :msg');
        params.ExpressionAttributeNames['#msg'] = 'msg';
        params.ExpressionAttributeValues[':msg'] = filters.type.msg;
      }
      if (typeof filters.type.vehicle === 'string' && filters.type.vehicle.length > 0) {
        filterExpression.push('#type.#vehicle = :vehicle');
        params.ExpressionAttributeNames['#vehicle'] = 'vehicle';
        params.ExpressionAttributeValues[':vehicle'] = filters.type.vehicle;
      }
      if (typeof filters.type.version === 'string' && filters.type.version.length > 0) {
        filterExpression.push('#type.#version = :version');
        params.ExpressionAttributeNames['#version'] = 'version';
        params.ExpressionAttributeValues[':version'] = filters.type.version;
      }
    }
  }

  if (filterExpression.length > 0) {
    params.FilterExpression = filterExpression.join(' and ');
    let data = null;
    if (paginationOptions && paginationOptions.nextTokens[paginationOptions.page - 1]) {
      params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
    }
    if (clientIndex.enable) {
      params.IndexName = 'client-id-index';
      params.KeyConditionExpression = '#client = :client';
      params.ExpressionAttributeNames['#client'] = 'client';
      params.ExpressionAttributeValues[':client'] = clientIndex.id;
      if (getCount) {
        return DDB.query(params).promise();
      }
      data = await DDB.query(params).promise();
    } else {
      if (Object.keys(params.ExpressionAttributeValues).length === 0) {
        delete params.ExpressionAttributeValues;
      }
      if (getCount) {
        return DDB.scan(params).promise();
      }
      data = await DDB.scan(params).promise();
    }
    if (paginationOptions && typeof paginationOptions.limit === 'number') {
      let items = data.Items.slice(0, paginationOptions.limit);
      let lastEvaluatedKey = undefined;
      if (items.length > 0) {
        let lastItem = items[items.length - 1];
        if (clientIndex.enable) {
          lastEvaluatedKey = { client: lastItem.client, id: lastItem.id };
        } else {
          lastEvaluatedKey = { id: lastItem.id };
        }
      }
      return {
        Items: items,
        Count: items.length,
        LastEvaluatedKey: lastEvaluatedKey,
      };
    }
    return data;
  }

  delete params.ExpressionAttributeNames;
  delete params.ExpressionAttributeValues;
  if (paginationOptions) {
    params.Limit = paginationOptions.limit;
    if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
      params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
    }
  }
  if (clientIndex.enable) {
    params.IndexName = 'client-id-index';
    params.KeyConditionExpression = '#client = :client';
    params.ExpressionAttributeNames = { '#client': 'client' };
    params.ExpressionAttributeValues = { ':client': clientIndex.id };
    return DDB.query(params).promise();
  }
  return DDB.scan(params).promise();
};

const getAll = (requestor, paginationOptions, filters) => query(requestor, false, paginationOptions, filters);

const getAllCount = (requestor, filters) => query(requestor, true, null, filters);

const batchGet = async (ids, fields) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    RequestItems: {},
    ReturnConsumedCapacity: 'NONE',
  };
  params.RequestItems[TableName] = {
    Keys: ids.map((id) => ({ id: id })),
  };
  if (fields && fields.length > 0) {
    params.RequestItems[TableName].ProjectionExpression = fields
      .map((f) =>
        f
          .split('.')
          .map((sf) => `#${sf}`)
          .join('.')
      )
      .join(', ');
    params.RequestItems[TableName].ExpressionAttributeNames = fields.reduce((acc, f) => {
      f.split('.').forEach((sf) => (acc[`#${sf}`] = sf));
      return acc;
    }, {});
  }
  let data = await DDB.batchGet(params).promise();
  return data.Responses[TableName];
};

export default {
  create,
  edit,
  remove,
  getAll,
  getAllCount,
  batchGet,
};
