import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  buttonsContainer: {
    marginBottom: '1rem',
  },
};
const MinValues = { eur: 0.5, usd: 0.5, gbp: 0.3, chf: 0.5 };

const Days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const Hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
class ChargeboxesDialogNewEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      unit: '',
      currency: 'eur',
      amount: '',
      minimumThreshold: null,
      showRemove: false,
      theshold_hour: '',
      start_charge: '',
      timeslotEnabled: false,
      amountInc: '',
      days: [
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
      ],
    };

    this.onChange = this.onChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.removeCustomPayment = this.removeCustomPayment.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    let { name, value } = e.target;
    switch (name) {
      case 'type':
        this.secureSetState({
          type: value,
          unit: '',
          currency: 'eur',
          amount: '',
          minimumThreshold: null,
          theshold_hour: '',
          start_charge: '',
          timeslotEnabled: false,
          amountInc: '',
          days: [
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
          ],
        });
        break;
      case 'amount':
        if (value.length === 0) {
          this.secureSetState({ [name]: '' });
        } else if (/^\d+(\.(\d+)?)?$/gim.test(value)) {
          this.secureSetState({ [name]: value });
        }
        break;
      case 'theshold_hour':
        if (value.length === 0) {
          this.secureSetState({ [name]: '' });
        } else if (/^\d+(\.(\d+)?)?$/gim.test(value)) {
          this.secureSetState({ [name]: value });
        }
        break;
      case 'start_charge':
        if (value.length === 0) {
          this.secureSetState({ [name]: '' });
        } else if (/^\d+(\.(\d+)?)?$/gim.test(value)) {
          this.secureSetState({ [name]: value });
        }
        break;
      case 'minimumThreshold':
        if (value.length === 0) {
          this.secureSetState({ [name]: null });
        } else if (/^\d+$/gim.test(value)) {
          this.secureSetState({ [name]: parseInt(value, 10) });
        }
        break;
      case 'timeslotEnabled':
        this.secureSetState({ [name]: !this.state[name] });
        break;
      case 'amountInc':
        if (value.length === 0) {
          this.secureSetState({ [name]: '' });
        } else if (/^\d+(\.(\d+)?)?$/gim.test(value)) {
          this.secureSetState({ [name]: value });
        }
        break;
      case '0.start':
      case '1.start':
      case '2.start':
      case '3.start':
      case '4.start':
      case '5.start':
      case '6.start':
      case '0.end':
      case '1.end':
      case '2.end':
      case '3.end':
      case '4.end':
      case '5.end':
      case '6.end':
        let names = name.split('.');
        let days = [...this.state.days];
        days[parseInt(names[0])][names[1]] = value;

        if (names[1] === 'end') {
          if (value < days[parseInt(names[0])].start) {
            break;
          }
        }
        this.secureSetState({ days: days });
        break;
      default:
        this.secureSetState({ [name]: value });
        break;
    }
  }

  cancel() {
    this.props.handleClose(false, null);
    this.secureSetState({ type: '', unit: '', currency: 'eur', amount: '', minimumThreshold: null, theshold_hour: '', start_charge: '' });
  }

  canSubmit() {
    switch (this.state.type) {
      case 'free':
        return true;

      case 'time-based':
        return (
          this.state.unit.length > 0 &&
          //this.state.currency.length > 0 &&
          /^\d+(\.\d+)?$/gim.test(this.state.amount)
        );
      case 'consume-based':
        if (this.state.timeslotEnabled === true) {
          if (
            /^\d+(\.\d+)?$/gim.test(this.state.amountInc) &&
            this.state.days[0].start <= this.state.days[0].end &&
            this.state.days[0].start !== '' &&
            this.state.days[0].end !== '' &&
            this.state.days[1].start <= this.state.days[1].end &&
            this.state.days[1].start !== '' &&
            this.state.days[1].end !== '' &&
            this.state.days[2].start <= this.state.days[2].end &&
            this.state.days[2].start !== '' &&
            this.state.days[2].end !== '' &&
            this.state.days[3].start <= this.state.days[3].end &&
            this.state.days[3].start !== '' &&
            this.state.days[3].end !== '' &&
            this.state.days[4].start <= this.state.days[4].end &&
            this.state.days[4].start !== '' &&
            this.state.days[4].end !== '' &&
            this.state.days[5].start <= this.state.days[5].end &&
            this.state.days[5].start !== '' &&
            this.state.days[5].end !== '' &&
            this.state.days[6].start <= this.state.days[6].end &&
            this.state.days[6].start !== '' &&
            this.state.days[6].end !== ''
          ) {
            return (
              this.state.unit.length > 0 &&
              //this.state.currency.length > 0 &&
              /^\d+(\.\d+)?$/gim.test(this.state.amount)
            );
          } else {
            return false;
          }
        } else {
          return (
            this.state.unit.length > 0 &&
            //this.state.currency.length > 0 &&
            /^\d+(\.\d+)?$/gim.test(this.state.amount)
          );
        }

      case 'flat':
        return (
          this.state.currency.length > 0 &&
          /^\d+(\.\d+)?$/gim.test(this.state.amount) &&
          MinValues[this.state.currency] <= parseFloat(this.state.amount) &&
          this.state.minimumThreshold !== null
        );
      case 'api':
        return true;

      default:
        return false;
    }
  }

  removeCustomPayment() {
    this.props.handleClose(true, null);
    this.secureSetState({
      type: '',
      unit: '',
      currency: 'eur',
      amount: '',
      minimumThreshold: null,
      timeslotEnabled: false,
      amountInc: '',
      days: [
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
        { start: '', end: '' },
      ],
    });
  }

  submit() {
    let options = {};
    switch (this.state.type) {
      case 'time-based':
        options = {
          amount: parseFloat(this.state.amount),
          unit: this.state.unit,
          start_charge: parseFloat(this.state.start_charge),
        };
        break;
      case 'consume-based':
        options = {
          unit: this.state.unit,
          currency: this.state.currency,
          amount: parseFloat(this.state.amount),
          theshold_hour: parseFloat(this.state.theshold_hour),
          start_charge: parseFloat(this.state.start_charge),
          timeslotEnabled: this.state.timeslotEnabled,
        };

        if (this.state.timeslotEnabled) {
          options.amountInc = parseFloat(this.state.amountInc);
          options.days = this.state.days;
        }
        break;
      case 'api':
        options = {};
        break;
      case 'flat':
        options = {
          minimum_threshold: this.state.minimumThreshold,
          currency: this.state.currency,
          amount: parseFloat(this.state.amount),
        };
        break;

      default:
        break;
    }
    this.props.handleClose(true, { type: this.state.type, options });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.chargebox.hasOwnProperty('custom_payment') && this.props.chargebox.custom_payment !== null) {
        let type = this.props.chargebox.custom_payment.type;
        let unit = this.props.chargebox.custom_payment.options.unit || '';
        let currency = this.props.currencies[this.props.chargebox.client] || 'eur';
        let amount = this.props.chargebox.custom_payment.options.hasOwnProperty('amount') ? this.props.chargebox.custom_payment.options.amount.toString() : '';
        let theshold_hour = this.props.chargebox.custom_payment.options.hasOwnProperty('theshold_hour')
          ? this.props.chargebox.custom_payment.options.theshold_hour.toString()
          : '';
        let start_charge = this.props.chargebox.custom_payment.options.hasOwnProperty('start_charge')
          ? this.props.chargebox.custom_payment.options.start_charge.toString()
          : '';
        let minimumThreshold = this.props.chargebox.custom_payment.options.minimum_threshold || null;
        let timeslotEnabled = this.props.chargebox.custom_payment.options.timeslotEnabled;
        let amountInc = this.props.chargebox.custom_payment.options.hasOwnProperty('amountInc')
          ? this.props.chargebox.custom_payment.options.amountInc.toString()
          : '';
        let days = this.props.chargebox.custom_payment.options.hasOwnProperty('days')
          ? this.props.chargebox.custom_payment.options.days
          : [
              { start: '', end: '' },
              { start: '', end: '' },
              { start: '', end: '' },
              { start: '', end: '' },
              { start: '', end: '' },
              { start: '', end: '' },
              { start: '', end: '' },
            ];
        this.secureSetState({
          type,
          unit,
          currency,
          amount,
          minimumThreshold,
          showRemove: true,
          theshold_hour,
          start_charge,
          timeslotEnabled,
          amountInc,
          days,
        });
      } else {
        this.secureSetState({
          type: '',
          unit: '',
          currency: 'eur',
          amount: '',
          minimumThreshold: null,
          showRemove: false,
          theshold_hour: '',
          start_charge: '',
          timeslotEnabled: false,
          amountInc: '',
          days: [
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
            { start: '', end: '' },
          ],
        });
      }
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    let fields = null;
    let currency = '€';
    if (this.props.chargebox && this.props.chargebox.client && this.props.chargebox.client.id) {
      switch (this.props.currencies[this.props.chargebox.client.id]) {
        case 'chf':
          currency = 'chf';
          break;
        case 'eur':
        default:
          currency = '€';
          break;
      }
    }
    switch (this.state.type) {
      case 'time-based':
        fields = (
          <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
            <FormControl>
              <InputLabel id='chargebox-custom-payment-time-unit'>
                <Translate id='paymentTypes.timeUnit' />
              </InputLabel>
              <Select labelId='chargebox-custom-payment-time-unit' name='unit' value={this.state.unit} onChange={this.onChange}>
                <MenuItem value='min'>
                  <Translate id='paymentTypes.minute' />
                </MenuItem>
                <MenuItem value='hour'>
                  <Translate id='paymentTypes.hour' />
                </MenuItem>
              </Select>
            </FormControl>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={this.props.translate('paymentTypes.amount')}
                  value={this.state.amount}
                  name='amount'
                  onChange={this.onChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='payment-type-start_charge'>
                    <Translate id='paymentTypes.start_charge' />
                  </InputLabel>
                  <Input
                    label={this.props.translate('paymentTypes.start_charge')}
                    value={this.state.start_charge}
                    name='start_charge'
                    onChange={this.onChange}
                    fullWidth
                    startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                  />
                </FormControl>
                <Typography variant='caption'>
                  <Translate id='paymentTypes.start_charge1' />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
        break;
      case 'consume-based':
        fields = (
          <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
            <FormControl>
              <InputLabel id='chargebox-custom-payment-consume-unit'>
                <Translate id='paymentTypes.consumeUnit' />
              </InputLabel>
              <Select labelId='chargebox-custom-payment-consume-unit' name='unit' value={this.state.unit} onChange={this.onChange}>
                <MenuItem value='wh'>
                  <Translate id='paymentTypes.wh' />
                </MenuItem>
                <MenuItem value='kwh'>
                  <Translate id='paymentTypes.kwh' />
                </MenuItem>
              </Select>
            </FormControl>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={this.props.translate('paymentTypes.amount')}
                  value={this.state.amount}
                  name='amount'
                  onChange={this.onChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='payment-type-theshold_hour'>
                    <Translate id='paymentTypes.theshold_hour' />
                  </InputLabel>
                  <Input
                    label={this.props.translate('paymentTypes.theshold_hour')}
                    value={this.state.theshold_hour}
                    name='theshold_hour'
                    onChange={this.onChange}
                    fullWidth
                    startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                  />
                </FormControl>
                <Typography variant='caption'>
                  <Translate id='paymentTypes.theshold_hour1' />
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='payment-type-start_charge'>
                    <Translate id='paymentTypes.start_charge' />
                  </InputLabel>
                  <Input
                    label={this.props.translate('paymentTypes.start_charge')}
                    value={this.state.start_charge}
                    name='start_charge'
                    onChange={this.onChange}
                    fullWidth
                    startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                  />
                </FormControl>
                <Typography variant='caption'>
                  <Translate id='paymentTypes.start_charge1' />
                </Typography>
              </Grid>
            </Grid>
            <FormControlLabel
              value='end'
              control={<Checkbox checked={this.state.timeslotEnabled} name='timeslotEnabled' onClick={this.onChange} color='primary' />}
              label={this.props.translate('chargeboxes.timeslot')}
              labelPlacement='end'
            />
            {this.state.timeslotEnabled && (
              <>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.translate('chargeboxes.timeslotamount')}
                      value={this.state.amountInc}
                      name='amountInc'
                      onChange={this.onChange}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
                <br></br>
                <Table aria-label='table' size='small' fullWidth item>
                  <TableHead>
                    <TableCell></TableCell>
                    {Days.map((e) => (
                      <TableCell
                        align='center'
                        sx={{
                          margin: 'auto',
                        }}
                      >
                        <Translate id={`chargeboxes.days.${e}`} />
                      </TableCell>
                    ))}
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align='center'
                        sx={{
                          margin: 'auto',
                        }}
                      >
                        <Translate id={'chargeboxes.days.startHour'} />
                      </TableCell>
                      {Days.map((e) => (
                        <TableCell
                          align='center'
                          sx={{
                            margin: 'auto',
                          }}
                        >
                          <Select
                            labelId='chargebox-custom-payment-consume-unit'
                            name={`${Days.indexOf(e)}.start`}
                            value={this.state.days[Days.indexOf(e)].start}
                            onChange={this.onChange}
                          >
                            {Hours.map((h) => (
                              <MenuItem value={h}>{h}</MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align='center'
                        sx={{
                          margin: 'auto',
                        }}
                      >
                        <Translate id={'chargeboxes.days.finishHour'} />
                      </TableCell>
                      {Days.map((e) => (
                        <TableCell
                          align='center'
                          sx={{
                            margin: 'auto',
                          }}
                        >
                          <Select
                            labelId='chargebox-custom-payment-consume-unit'
                            name={`${Days.indexOf(e)}.end`}
                            value={this.state.days[Days.indexOf(e)].end}
                            onChange={this.onChange}
                          >
                            {Hours.map((h) => (
                              <MenuItem value={h}>{h}</MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
          </Grid>
        );
        break;
      case 'flat':
        fields = (
          <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={this.props.translate('paymentTypes.amount')}
                  value={this.state.amount}
                  name='amount'
                  onChange={this.onChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label={this.props.translate('paymentTypes.minimumThreshold')}
              value={this.state.minimumThreshold === null ? '' : this.state.minimumThreshold.toString()}
              name='minimumThreshold'
              onChange={this.onChange}
              fullWidth
            />
          </Grid>
        );
        break;

      default:
        break;
    }
    return (
      <Dialog onClose={this.cancel} aria-labelledby='chargebox-dialog-custompayment-title' open={this.props.open} maxWidth='md'>
        <DialogTitle id='chargebox-dialog-custompayment-title'>
          <Translate id='chargeboxes.dialogs.customPayment.title' />
        </DialogTitle>
        <DialogContent>
          <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2} className={this.props.classes.buttonsContainer}>
            <Grid item>
              <Button
                onClick={() => this.onChange({ target: { name: 'type', value: 'free' } })}
                variant='contained'
                color={this.state.type === 'free' ? 'primary' : 'default'}
              >
                <Translate id='paymentTypes.free' />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => this.onChange({ target: { name: 'type', value: 'time-based' } })}
                variant='contained'
                color={this.state.type === 'time-based' ? 'primary' : 'default'}
              >
                <Translate id='paymentTypes.time-based' />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => this.onChange({ target: { name: 'type', value: 'consume-based' } })}
                variant='contained'
                color={this.state.type === 'consume-based' ? 'primary' : 'default'}
              >
                <Translate id='paymentTypes.consume-based' />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => this.onChange({ target: { name: 'type', value: 'flat' } })}
                variant='contained'
                color={this.state.type === 'flat' ? 'primary' : 'default'}
              >
                <Translate id='paymentTypes.flat' />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => this.onChange({ target: { name: 'type', value: 'api' } })}
                variant='contained'
                color={this.state.type === 'api' ? 'primary' : 'default'}
              >
                <Translate id='paymentTypes.api' />
              </Button>
            </Grid>
          </Grid>
          {fields}
        </DialogContent>
        <DialogActions>
          <Grid container direction='row' alignItems='center' justifyContent='space-between'>
            <Grid item>
              {this.state.showRemove && (
                <Button onClick={this.removeCustomPayment} color='primary'>
                  <Translate id='chargeboxes.dialogs.customPayment.removeCustomPayment' />
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={this.cancel} color='default' autoFocus>
                <Translate id='chargeboxes.dialogs.close' />
              </Button>
              <Button onClick={this.submit} color='primary' disabled={!this.canSubmit()}>
                <Translate id='chargeboxes.dialogs.customPayment.submit' />
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(ChargeboxesDialogNewEdit));
